<template>
    <div>
      <!-- Google AdSense placeholder -->
      <ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-8764980996081941"
     data-ad-slot="1166903782"></ins>
    </div>
  
  </template>
  
  <script>

  export default {
    name: 'GoogleAd2',
    mounted() {
      // This is where you dynamically load the Google AdSense script
      //// (adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
  </script>
  