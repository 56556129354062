import { createRouter, createWebHistory } from 'vue-router';
import GoogleAd1 from '../components/GoogleAd1.vue';
import GoogleAd3 from '../components/GoogleAd3.vue';
import GoogleAd2 from '../components/GoogleAd2.vue';

import HomePage from "../components/pages/HomePage.vue"
import NotFound from "../components/pages/NotFound.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage, // Define the homepage route
  },  
  {
    path: '/ads/1',
    name: 'Ads1',
    component: GoogleAd1,
  },
  {
    path: '/ads/2',
    name: 'Ads2',
    component: GoogleAd2,
  },
  {
    path: '/ads/3',
    name: 'Ads3',
    component: GoogleAd3,
  },
  {
    path: '/ads/testinggame',
    name: 'Testgame',
    component: GoogleAd1,
  },  
  // Other routes...

  // Catch-all route for unknown paths
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;