<template>
    <div>
      <!-- Google AdSense placeholder -->

      <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8764980996081941"
     data-ad-slot="3922712028"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    </div>
    <div>
      Title
    </div>
    <div class="game-container">
    <iframe
      src="https://cloud.freezenova.com/games/2022/unity/highway-traffic/index.html"
      width="100%"
      height="600"
      frameborder="0"
      allowfullscreen
    ></iframe>   
    </div>

  </template>
  
  <script>
 

  export default {
    name: 'GoogleAd3',
    mounted() {
      // This is where you dynamically load the Google AdSense script
      //// (adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
  </script>
  