<template>
  <div style="height:100px">
    <GoogleAd1 />                          
  </div>
    
</template>
  
<script>

import GoogleAd1 from '../GoogleAd1.vue';


  export default {
    name: 'HomePage',
    components: {
      GoogleAd1,
      

  }    
};


</script>