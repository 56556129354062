<template>
    <div>
      <!-- Google AdSense placeholder -->
      <ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-8764980996081941"
     data-ad-slot="7201286520"></ins>

      <!-- Google AdSense placeholder -->
      <ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-8764980996081941"
     data-ad-slot="1166903782"></ins>     

     <ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-8764980996081941"
     data-ad-slot="7201286520"></ins>     
    </div>
    
</template>
  
<script>

  export default {
    name: 'GoogleAd1',
    mounted() {
      const script = document.createElement('script');
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.head.appendChild(script);
  
      // Create a temporary reference to adsbygoogle
      script.onload = () => {
        const adsByGoogle = window.adsbygoogle || [];
        adsByGoogle.push({});
        const adsByGoogle2 = window.adsbygoogle || [];
        adsByGoogle2.push({});
        const adsByGoogle3 = window.adsbygoogle || [];
        adsByGoogle3.push({});                           
      };
    }
  };
  </script>
  