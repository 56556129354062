import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles.css'  // Global CSS

//createApp(App).mount('#app')

import router from './router';  // Import the router

// Create the Vue app instance
const app = createApp(App);

// Use the router in your app
app.use(router);

// Mount the app
app.mount('#app');
